import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Accs from "../acc/accs";
import {AppBar, Drawer, MainListItems, secondListItems, thirdListItems} from "./side";
import Devices from "../device/devices";
import {CombinedContext} from "../../state/context";
import Jobs from "../job/jobs";
import {DateContext} from "../../state/filters";
import Apps from "../app/app";
import {ViewContext} from "../../state/view";
import Orders from "../order/order";
import SettingsPage from "../settings/settings";


export default function Dashboard() {
    const [open, setOpen] = React.useState(false);
    const {view, handleViewChange} = React.useContext(ViewContext);
    const {refreshAccs} = React.useContext(CombinedContext);
    const {refreshDevices} = React.useContext(CombinedContext);
    const {refreshJobs} = React.useContext(CombinedContext);
    const {refreshApps} = React.useContext(CombinedContext);
    const {refreshOrders} = React.useContext(CombinedContext);
    const {refreshSettings} = React.useContext(CombinedContext);
    const {selectedStartDate, selectedEndDate,} = React.useContext(DateContext);
    const {jobsStatus} = React.useContext(DateContext);


    const toggleDrawer = () => {
        setOpen(!open);
    };
    const changeView = (newView) => { // Add this function
        handleViewChange(newView);
    };

    React.useEffect(() => {
        switch (view) {
            case 'Accs':
                refreshAccs();
                refreshDevices();
                refreshJobs(selectedStartDate, selectedEndDate);
                break;
            case 'Devices':
                refreshDevices();
                break;
            case 'Jobs':
                refreshDevices();
                refreshJobs(selectedStartDate, selectedEndDate, jobsStatus);
                break;
            case 'Apps':
                refreshApps();
                break;
            case 'Orders':
                refreshOrders();
                break;
            case 'Settings':
                refreshSettings();
                break;
            default:
                break;
        }
    }, [view,
        refreshAccs,
        refreshDevices,
        refreshJobs,
        jobsStatus,
        selectedStartDate,
        selectedEndDate,
        refreshApps,
        refreshOrders, refreshSettings]);


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="absolute" open={open}>
                <Toolbar sx={{pr: '24px'}}>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && {display: 'none'}),
                                }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{flexGrow: 1}}>
                        {view}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{display: 'flex'}}>
                <Drawer variant="permanent" open={open}>
                    <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1]}}>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        {MainListItems(changeView)}
                    </List>
                    <Box sx={{flexGrow: 1}}/>
                    <List component="nav">
                        {secondListItems(changeView)}
                    </List>
                    <List component="nav">
                        <Divider sx={{my: 1}}/>
                        {thirdListItems()}
                    </List>
                </Drawer>
            </Box>
            <Box component="main" sx={{flexGrow: 1, height: '100vh', overflow: 'auto'}}>
                <Toolbar/>
                <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{p: 2, minHeight: '500px'}}>
                                {view === 'Apps' && <Apps/>}
                                {view === 'Accs' && <Accs/>}
                                {view === 'Devices' && <Devices/>}
                                {view === 'Jobs' && <Jobs/>}
                                {view === 'Orders' && <Orders/>}
                                {view === 'Settings' && <SettingsPage/>}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
