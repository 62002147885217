import * as React from 'react';
import {useContext} from 'react';
import Box from "@mui/material/Box";
import {CombinedContext} from "../../state/context";
import {useTheme} from "@mui/material/styles";
import {LinearProgress, Tooltip, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import {format} from "date-fns";
import {DataGrid} from "@mui/x-data-grid";
import {PatchOrder} from "../../services/api";
import {toast} from "react-toastify";


export default function Orders() {
    const {orders, setOrders} = useContext(CombinedContext);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOrderStatus = (orderUUID, status) => {
        PatchOrder(orderUUID, {status: status})
        setOrders(prevOrders => prevOrders.map(order =>
            order.uuid === orderUUID ? {...order, status} : order
        ));
        toast.success(`Order ${status} successfully`);
    };

    const columns = [
        {
            field: 'appIcon',
            headerName: '',
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={params.value} alt="App Icon"
                         style={{width: '32px', height: '32px', borderRadius: '20%'}}/>
                </Box>
            ),
        },
        {
            field: 'keyword',
            headerName: 'Keyword',
            width: 200,
        },
        {field: 'installs', headerName: 'Total', width: 55},
        {
            field: 'progress',
            headerName: 'Progress',
            width: 200,
            renderCell: (params) => (
                <Tooltip title={`Done: ${params.row.done}`}>
                    <Box sx={{height: 35, width: '100%', display: 'flex', alignItems: 'center'}}>
                        <LinearProgress
                            variant="determinate"
                            value={params.value * 100}
                            color={params.row.status === 'finished' ? 'success' : 'primary'}
                            style={{
                                width: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            }}
                        />
                    </Box>
                </Tooltip>
            ),
        },
        {field: 'cost', headerName: 'Cost', width: 100},
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 160,
            valueFormatter: (params) => format(new Date(params.value), 'HH:mm / dd.MM.yyyy'),
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 60,
            renderCell: (params) => {
                if (params.row.status === 'stopped') {
                    return (
                        <IconButton color="primary" onClick={() => handleOrderStatus(params.row.uuid, 'running')}>
                            <PlayArrowIcon/>
                        </IconButton>
                    );
                } else if (params.row.status === 'finished') {
                    return null
                } else {
                    return (
                        <IconButton color="secondary" onClick={() => handleOrderStatus(params.row.uuid, 'stopped')}>
                            <StopIcon/>
                        </IconButton>
                    );
                }
            },
        },
    ]


    return (
        <React.Fragment>
            <Box sx={{height: 'auto', width: '100%'}}>
                <DataGrid
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        minHeight: 400,
                        fontSize: isMobile ? '0.8rem' : '1rem',
                    }}
                    rows={orders.map((row, index) => {
                        const progress = (row.installs && row.installs_done) ? (row.installs_done / row.installs) : 0;
                        return {
                            id: index,
                            uuid: row.uuid,
                            appIcon: row.app_icon_url,
                            keyword: row.keyword,
                            installs: row.installs,
                            done: row.installs_done,
                            progress: progress,
                            status: row.status,
                            cost: row.price,
                            created_at: row.created_at
                        };
                    })}
                    columns={columns}
                    disableRowSelectionOnClick={true}
                    disableMultipleRowSelection={true}
                    disableSelectionOnClick={true}
                    rowSelectionModel="none"
                    pageSizeOptions={[5, 10, 25]}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                        sorting: {
                            sortModel: [{field: 'created_at', sort: 'desc'}],
                        },
                    }}
                />
            </Box>
        </React.Fragment>
    );
}
