import {useContext} from "react";
import {CombinedContext} from "../../state/context";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {Badge, Card, CardContent} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {differenceInHours, formatDistanceToNow} from "date-fns";
import Box from "@mui/material/Box";
import {PatchAccount} from "../../services/api";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";


export default function LoginAccDialog({loginAccDiag, setLoginAccDiag, accUUID}) {
    const {devices} = useContext(CombinedContext);
    const {refreshAccs} = useContext(CombinedContext);


    // Sort devices by name
    const sortedDevices = [...devices]
        .filter(device => device.status === 'active')
        .sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name));

    const handleDeviceChange = (acc_uuid, device_uuid) => {
        PatchAccount(acc_uuid, {device_uuid}).then(() => {
            refreshAccs();
        });
    }

    return (
        <Dialog open={loginAccDiag} onClose={() => setLoginAccDiag(false)}>
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} paddingTop={2} wrap={"wrap"}>
                    {sortedDevices.map((device) => (
                        <Grid item xs={1} sm={6} md={4} key={device.uuid}>
                            <Badge badgeContent={device.accounts ? device.accounts.length : 0} color="primary"
                                   sx={{width: '100%', height: '100%'}}
                            >
                                <Card
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: '#232323',
                                        '&:hover': {
                                            backgroundColor: '#1a1a1a',
                                        },
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onClick={() => {
                                        handleDeviceChange(accUUID, device.uuid);
                                        setLoginAccDiag(false);
                                    }}>
                                    <CardContent>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="title">
                                                {device.name}
                                            </Typography>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                            <LoginOutlinedIcon
                                                sx={{fontSize: 15, color: '#7a7a7a', marginRight: 0.3}}/>
                                            <Typography variant="body2" color='#7a7a7a' sx={{fontSize: 12}}>
                                                {renderLastLogin({value: device.last_login})}
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Badge>
                        </Grid>
                    ))}
                </Grid>


            </DialogContent>
            <DialogActions>
                <Button onClick={() => setLoginAccDiag(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

function renderLastLogin(params) {
    const lastLoginUTC = new Date(params.value);
    const diffHours = Math.abs(differenceInHours(new Date(), lastLoginUTC));

    let color;
    if (diffHours < 2) {
        color = '#ff422b';
    } else if (diffHours < 4) {
        color = 'orange';
    } else if (diffHours < 6) {
        color = 'yellow';
    }

    return (
        <Box display="flex" alignItems="center">
            <Box>{formatDistanceToNow(lastLoginUTC, {addSuffix: true})}</Box>
            <Box
                sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: color,
                    marginLeft: 1,
                }}
            />
        </Box>
    );
}
