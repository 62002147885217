import * as React from 'react';
import {useContext} from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {differenceInHours, differenceInMinutes, format, formatDistanceToNow} from 'date-fns';
import {DevicePath} from "../../services/api";
import jdenticon from "jdenticon/standalone";
import {CombinedContext} from "../../state/context";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {toast} from "react-toastify";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';


export default function Devices() {
    const {devices, refreshDevices} = useContext(CombinedContext);

    const handleStatusChange = (acc_uuid, status) => {
        DevicePath(acc_uuid, {status}).then(() => {
            refreshDevices();
        });
    }

    const handleAutoLoginChange = (acc_uuid, value) => {
        const auto_login = value
        DevicePath(acc_uuid, {auto_login}).then(() => {
            refreshDevices();
        });
    }

    const columns = [
        {
            field: 'icon',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <Tooltip title={`${params.row.uuid} `}>
                    <IconButton
                        sx={{
                            padding: 0,
                            margin: 0,
                            color: 'inherit',
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={() => {
                            navigator.clipboard.writeText(params.row.uuid);
                            toast.success("Copied to clipboard!");
                        }}
                    >
                        <div
                            dangerouslySetInnerHTML={{__html: jdenticon.toSvg(params.value, 35)}}/>
                    </IconButton>
                </Tooltip>

            ),
        },
        {
            field: 'last_seen',
            headerName: 'Seen',
            width: 50,
            renderCell: (params) => {
                const lastSeenUTC = new Date(params.value);
                const diffMin = Math.abs(differenceInMinutes(new Date(), lastSeenUTC));

                let color;
                if (diffMin > 30) {
                    color = '#ff422b';
                } else if (diffMin > 15) {
                    color = 'orange';
                } else if (diffMin > 10) {
                    color = 'yellow';
                } else {
                    color = '#18ce18';
                }

                return (
                    <Tooltip title={diffMin + ' min ago'}>
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                            <Box
                                sx={{
                                    width: 10,
                                    height: 10,
                                    borderRadius: '50%',
                                    backgroundColor: color,
                                }}
                            />
                        </Box>
                    </Tooltip>
                );
            },
        },
        {field: 'serial', headerName: 'Serial', width: 140},
        {field: 'name', headerName: 'Name', width: 130, editable: true},
        {field: 'model', headerName: 'Model', width: 100, editable: true},
        {field: 'accounts', headerName: 'Accounts', width: 70},
        {field: 'install_delay', headerName: 'Install Delay', editable: true, width: 120},
        {
            field: 'last_login',
            headerName: 'Last Login',
            width: 150,
            renderCell: (params) => {
                const lastLoginUTC = new Date(params.value);
                const diffHours = Math.abs(differenceInHours(new Date(), lastLoginUTC));

                let color;
                if (diffHours < 2) {
                    color = '#ff422b';
                } else if (diffHours < 4) {
                    color = 'orange';
                } else if (diffHours < 6) {
                    color = 'yellow';
                }

                return (
                    <Box display="flex" alignItems="center">
                        <Box>{formatDistanceToNow(lastLoginUTC, {addSuffix: true})}</Box>
                        <Box
                            sx={{
                                width: 6,
                                height: 6,
                                borderRadius: '50%',
                                backgroundColor: color,
                                marginLeft: 1,
                            }}
                        />
                    </Box>

                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Created at',
            width: 150,
            valueFormatter: (params) => format(new Date(params.value), 'HH:mm / dd.MM.yyyy'),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 90,
            renderCell: (params) => {
                return (
                    <Box display="flex" alignItems="center" width="100%" height="100%">
                        <ChangeStateSwitch
                            uuid={params.row.uuid}
                            status={params.row.status}
                            handleStatusChange={handleStatusChange}/>
                        <AutoLoginSwitch
                            uuid={params.row.uuid}
                            autoLogin={params.row.auto_login}
                            status={params.row.status}
                            handleAutoLoginChange={handleAutoLoginChange}
                        />

                    </Box>
                );
            },
        },
    ];


    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        const {uuid, name, model, serial, os, auto_touch, app_version, install_delay} = updatedRow;
        DevicePath(uuid,
            {name, model, serial_number: serial, os_version: os, auto_touch, app_version, install_delay}).then(r => {
            toast.success('Device updated');
        });

        return updatedRow;
    };


    return (<React.Fragment>
            <Box sx={{height: 'auto', width: '100%'}}>
                <DataGrid
                    columns={columns}
                    rows={devices.map((device, index) => ({
                        id: index,
                        icon: device.uuid,
                        uuid: device.uuid,
                        serial: device.serial_number,
                        name: device.name,
                        model: device.model,
                        os: device.os_version,
                        auto_touch: device.auto_touch,
                        app_version: device.app_version,
                        install_delay: device.install_delay,
                        accounts: Array.isArray(device.accounts) ? device.accounts.filter(account => account.status === 'logged in').length : 'N/A',
                        status: device.status,
                        last_login: device.last_login,
                        auto_login: device.auto_login,
                        last_seen: device.last_seen,
                        created_at: device.created_at,
                    }))}
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        minHeight: 400,
                    }}
                    disableRowSelectionOnClick={true}
                    disableMultipleRowSelection={true}
                    disableSelectionOnClick={true}
                    rowSelectionModel="none"
                    pageSizeOptions={[5, 10, 25]}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                        sorting: {
                            sortModel: [{field: 'created_at', sort: 'desc'}],
                        },
                    }}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={() => {
                    }}
                />
            </Box>
        </React.Fragment>
    );
}

function ChangeStateSwitch({uuid, status, handleStatusChange}) {
    return (
        <Tooltip title={status === 'active' ? 'Deactivate' : 'Activate'}>
            <IconButton
                sx={{
                    color: status === 'active' ? '#18ce18' : '#ff422b',
                }}
                onClick={(event) => handleStatusChange(uuid, status === 'active' ? 'inactive' : 'active')}
            >
                {status === 'active' ? <PowerSettingsNewIcon/> : <PowerSettingsNewIcon/>}
            </IconButton>
        </Tooltip>
    );
}

function AutoLoginSwitch({uuid, autoLogin, status, handleAutoLoginChange}) {
    if (status !== 'active') {
        return null;
    }

    return (
        <Tooltip title={autoLogin ? 'Disable Auto Login' : 'Enable Auto Login'}>
            <IconButton
                color={autoLogin ? "primary" : "secondary"}
                onClick={() => handleAutoLoginChange(uuid, !autoLogin)}
            >
                {autoLogin ? <StopIcon/> : <PlayArrowIcon/>}
            </IconButton>
        </Tooltip>
    );
}



