import * as React from 'react';
import {useContext, useEffect} from 'react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import {DateContext} from "../../state/filters";
import {FormControl, InputLabel} from "@mui/material";
import {CombinedContext} from "../../state/context";

export default function Filters() {
    const {dateRange, setDateRange} = useContext(DateContext);
    const {selectedStartDate, setSelectedStartDate, selectedEndDate, setSelectedEndDate} = useContext(DateContext);
    const {jobsStatus, setJobsStatus} = useContext(DateContext);
    const {refreshJobs} = useContext(CombinedContext);


    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        setDateRange('');
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
        setDateRange('');
    };


    const calculateDates = (range) => {
        const date = new Date();

        const start = new Date();
        const end = new Date();

        switch (range) {
            case 'lastMonth':
                start.setMonth(date.getMonth() - 1);
                break;
            case 'last7Days':
                start.setDate(date.getDate() - 7);
                break;
            case 'today':
                break;
            case 'yesterday':
                start.setDate(date.getDate() - 1);
                end.setDate(date.getDate() - 1);
                break;
            default:
                break;
        }

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        setSelectedStartDate(start);
        setSelectedEndDate(end);
    };

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
        calculateDates(event.target.value);
    };

    useEffect(() => {
        if (dateRange !== "") {
            calculateDates(dateRange);
        } // eslint-disable-next-line
    }, [dateRange]);

    return (
        <Box display="flex" alignItems="center">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl sx={{width: 150}}>
                    <InputLabel>Preset</InputLabel>
                    <Select
                        onChange={handleDateRangeChange}
                        label="Presets"
                        value={dateRange}
                        sx={{mr: 2, backgroundColor: 'rgba(0, 0, 0, 0.2)'}}
                    >
                        <MenuItem value={'today'}>Today</MenuItem>
                        <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                        <MenuItem value={'last7Days'}>Last 7 Days</MenuItem>
                        <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                    </Select>
                </FormControl>
                <DatePicker
                    label="Since"
                    format="dd.MM.yyyy"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    sx={{width: 150, mr: 2, backgroundColor: 'rgba(0, 0, 0, 0.15)'}}
                />
                <DatePicker
                    label="Until"
                    format="dd.MM.yyyy"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    sx={{width: 150, backgroundColor: 'rgba(0, 0, 0, 0.15)'}}
                />

            </LocalizationProvider>
            <FormControl sx={{width: 150, ml: 2}}>
                <InputLabel>Status</InputLabel>
                <Select
                    value={jobsStatus}
                    label="Status"
                    onChange={(event) => {
                        setJobsStatus(event.target.value);
                        refreshJobs(selectedStartDate, selectedEndDate, event.target.value);
                    }}
                    sx={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}
                >
                    <MenuItem value={''}>All</MenuItem>
                    <MenuItem value={'new'}>New</MenuItem>
                    <MenuItem value={'success'}>Success</MenuItem>
                    <MenuItem value={'failed'}>Failed</MenuItem>
                    <MenuItem value={'processing'}>Processing</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
