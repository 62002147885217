import React, {createContext, useState} from 'react';

export const ViewContext = createContext();

export const ViewProvider = ({children}) => {
    const localStorageKey = 'sk_adm_settings';
    const [view, setView] = useState(() => {
            const savedView = localStorage.getItem(localStorageKey);
            return savedView ? savedView : 'Accs';
        }
    );

    const handleViewChange = (newView) => {
        setView(newView);
        localStorage.setItem(localStorageKey, newView);
    };


    return (
        <ViewContext.Provider value={{view, handleViewChange}}>
            {children}
        </ViewContext.Provider>
    );
};
