import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import * as React from "react";
import {useContext} from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonIcon from "@mui/icons-material/Person";
import ListItemText from "@mui/material/ListItemText";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LogoutIcon from "@mui/icons-material/Logout";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import {rgba} from "polished";
import {ViewContext} from "../../state/view";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsIcon from "@mui/icons-material/Settings";


const drawerWidth = 160;

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const StyledListItemText = styled(ListItemText)({
    color: rgba(255, 255, 255, 0.7),
});

const tabs = [
    {name: 'Apps', icon: <AppsRoundedIcon/>},
    {name: 'Accs', icon: <PersonIcon/>},
    {name: 'Devices', icon: <PhoneIphoneIcon/>},
    {name: 'Jobs', icon: <WorkOutlineIcon/>},
    {name: 'Orders', icon: <ShoppingCartIcon/>},
]; // Add more tabs as needed

export const MainListItems = (changeView) => {
    const {view} = useContext(ViewContext);

    return (
        <React.Fragment>
            {tabs.map(tab => (
                <ListItemButton key={tab.name} selected={view === tab.name} onClick={() => changeView(tab.name)}>
                    <ListItemIcon>
                        {tab.icon}
                    </ListItemIcon>
                    <StyledListItemText primary={tab.name}/>
                </ListItemButton>
            ))}
        </React.Fragment>
    );
};

export const secondListItems = (changeView) => (
    <React.Fragment>
        <ListItemButton onClick={() => changeView('Settings')}>
            <ListItemIcon>
                <SettingsIcon/>
            </ListItemIcon>
            <StyledListItemText primary="Settings"/>
        </ListItemButton>
    </React.Fragment>
);

export const thirdListItems = () => (
    <React.Fragment>
        <ListItemButton onClick={() => {
            window.localStorage.removeItem('token'); // remove token
            window.location.href = '/auth';
        }}>
            <ListItemIcon>
                <LogoutIcon/>
            </ListItemIcon>
            <StyledListItemText primary="Logout"/>
        </ListItemButton>
    </React.Fragment>
);
