import './App.css';
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useEffect} from "react";
import {ThemeProvider} from "@mui/material/styles";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {createTheme} from "@mui/material";
import AuthView from "./features/auth/auth";
import Dashboard from "./features/dashboard/dashboard";
import {CombinedProvider} from "./state/context";
import {DateProvider} from "./state/filters";
import {ViewProvider} from "./state/view";


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function CheckToken() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token && location.pathname === '/dashboard') {
            navigate('/auth');
        }
    }, [navigate, location]);

    return null;
}

function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/auth');
    }, [navigate]);
    return null;
}

function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <ViewProvider>
                <CombinedProvider>
                    <DateProvider>
                        <ToastContainer theme="dark"/>

                        <Router>
                            <CheckToken/>
                            <Routes>
                                <Route path="/auth" element={<AuthView/>}/>
                                <Route path="/dashboard" element={<Dashboard/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                        </Router>
                    </DateProvider>
                </CombinedProvider>
            </ViewProvider>
        </ThemeProvider>
    );
}

export default App;
