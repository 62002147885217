import axios from 'axios';
import {toast} from "react-toastify";

const API_URL = 'https://api.sofikey.io/admin';

export const GetAccounts = async () => {
    try {
        const response = await axios.get(`${API_URL}/accounts`, {
            headers: {
                Authorization: `basic ${localStorage.getItem('token')}`,
                "Content-Type": "application/json",
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed get accounts:', error);
    }
}

export const CheckAuth = async (login, password) => {
    try {
        const response = await axios.get(`${API_URL}/accounts`, {
            headers: {
                Authorization: `basic ${btoa(`${login}:${password}`)}`,
                "Content-Type": "application/json",
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed verify credentials:', error);
    }
}

export const AddAccount = async (accounts) => {
    try {
        const response = await axios.post(`${API_URL}/account`, {
            accounts
        }, {
            headers: {
                Authorization: `basic ${localStorage.getItem('token')}`,
                "Content-Type": "application/json",
            }
        });

        if (response.status === 201) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed add account:', error);
    }
}

export const GetDevices = async () => {
    try {
        const response = await axios.get(`${API_URL}/devices`, {
            headers: {
                'accept': 'application/json',
                Authorization: `basic ${localStorage.getItem('token')}`,
                "Content-Type": "application/json",
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed get device:', error);
    }
}

export const DevicePath = async (uuid, device) => {
    try {
        const response = await axios.patch(`${API_URL}/device/${uuid}`, {
            name: device.name,
            model: device.model,
            os_version: device.os_version,
            app_version: device.app_version,
            auto_touch: device.auto_touch,
            serial_number: device.serial_number,
            install_delay: parseInt(device.install_delay),
            auto_login: device.auto_login,
            status: device.status,
        }, {
            headers: {
                'accept': 'application/json',
                Authorization: `basic ${localStorage.getItem('token')}`,
                "Content-Type": "application/json",
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed update device:', error);
    }
}

export const GetJobs = async (since, until, status) => {
    try {
        let params = {};
        if (since) params.since = since.toISOString();
        if (until) params.until = until.toISOString();
        if (status) params.status = status;
        const response = await axios.get(`${API_URL}/jobs`, {
            params,
            headers: {
                Authorization: `basic ${localStorage.getItem('token')}`,
            }
        });


        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed get jobs:', error);
    }
}

export const GetApps = async () => {
    try {
        const response = await axios.get(`${API_URL}/apps`, {
            headers: {
                Authorization: `basic ${localStorage.getItem('token')}`,
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed get apps:', error);
    }
}

export const PatchApp = async (uuid, data) => {
    try {
        const response = await axios.patch(`${API_URL}/app/${uuid}`, {
            title: data.title,
            subtitle: data.subtitle,
            ui_name: data.ui_name,
            verified: data.verified,
        }, {
            headers: {
                Authorization: `basic ${localStorage.getItem('token')}`,
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed update app:', error);
    }
}

export const PatchAccount = async (uuid, data) => {
    try {
        const response = await axios.patch(`${API_URL}/account/${uuid}`, {
            device_uuid: data.device_uuid,
            password: data.password,
            email: data.email,
            status: data.status,
            state: data.state,
            tag: data.tag,
        }, {
            headers: {
                Authorization: `basic ${localStorage.getItem('token')}`,
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed update account:', error);
    }
}

export const GetOrders = async (since, until) => {
    try {
        let params = {};
        if (since) params.since = since.toISOString();
        if (until) params.until = until.toISOString();
        const response = await axios.get(`${API_URL}/orders`, {
            params,
            headers: {
                Authorization: `basic ${localStorage.getItem('token')}`,
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed get orders:', error);
    }
}

export const PatchOrder = async (uuid, data) => {
    try {
        const response = await axios.patch(`${API_URL}/order/${uuid}`, null, {
            params: {
                status: data.status,
            },
            headers: {
                Authorization: `basic ${localStorage.getItem('token')}`,
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed update order:', error);
    }

}

export const GetSettings = async () => {
    try {
        const response = await axios.get(`${API_URL}/settings`, {
            headers: {
                'accept': 'application/json',
                Authorization: `basic ${localStorage.getItem('token')}`,
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed get settings:', error);
    }
}

export const UpdateSettings = async (settings) => {
    console.log(settings);
    try {
        const response = await axios.patch(`${API_URL}/settings`, settings, {
            headers: {
                'accept': 'application/json',
                Authorization: `basic ${localStorage.getItem('token')}`,
            }
        });

        if (response.status === 200) {
            return response.data;
        }

    } catch (error) {
        toast.error('Failed update settings:', error);
    }
}
