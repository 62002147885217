import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {AddAccount} from "../../services/api";
import {InputAdornment} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {CombinedContext} from "../../state/context";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";

export default function CreateAccDialog({createAccDiag, setOpenAddDialog}) {
    const [isValid, setIsValid] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [deviceUUID, setDeviceUUID] = useState('');
    const [emailError, setEmailError] = useState("");
    const {refreshAccs} = useContext(CombinedContext);
    const {devices} = useContext(CombinedContext);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClose = () => {
        setOpenAddDialog(false);
        setLogin('');
        setPassword('');
        setIsValid(false);
    };

    useEffect(() => {
        if (devices.length > 0) {
            setDeviceUUID(devices[0].uuid);
        }
    }, [devices]);

    const handleAdd = () => {
        handleClose();
        AddAccount([
            {login: login, password: password, device_uuid: deviceUUID}]).then(() => {
            refreshAccs();
        });
    };

    const handleEmailChange = (event) => {
        setLogin(event.target.value);

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(event.target.value)) {
            setEmailError("Invalid email address");
            setIsValid(false);
        } else {
            setEmailError("");
            setIsValid(true);
        }
    };

    return (
        <Dialog open={createAccDiag} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle>Add New Account</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="login"
                    label="Login"
                    type="email"
                    fullWidth
                    value={login}
                    error={!!emailError}
                    helperText={emailError}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {isValid ? <CheckCircleIcon color="success"/> : login !== '' ?
                                    <ErrorIcon color="error"/> : null}
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleEmailChange}
                />
                <TextField
                    margin="dense"
                    id="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    value={password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Select
                    sx={{width: '100%', mt: 2}}
                    value={deviceUUID}
                    onChange={(e) => setDeviceUUID(e.target.value)}
                >
                    {devices.map((device) => (
                        <MenuItem key={device.uuid} value={device.uuid}>
                            {device.uuid} {device.name ? `(${device.name})` : ''}
                        </MenuItem>
                    ))}
                </Select>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd} disabled={!isValid || password === ''}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}
