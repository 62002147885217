import * as React from 'react';
import {useContext} from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {format} from 'date-fns';
import {CombinedContext} from "../../state/context";
import {Link, Switch} from "@mui/material";
import {PatchApp} from "../../services/api";

export default function Apps() {
    const {apps, refreshApps} = useContext(CombinedContext);

    const handleEdit = async (updatedRow) => {
        const {uuid, title, subtitle, ui_name, verified} = updatedRow;
        try {
            const updatedApp = await PatchApp(uuid,
                {title, subtitle, ui_name, verified});
            const index = apps.findIndex(app => app.uuid === uuid);
            const newApps = [...apps];
            newApps[index] = updatedApp;
            refreshApps(newApps);
        } catch (error) {
            console.error('Failed to update app:', error);
        }
    }


    const columns = [
        {
            field: 'icon',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Link href={params.row.url} target="_blank">
                        <img src={params.value} alt="App Icon"
                             style={{width: '32px', height: '32px', borderRadius: '20%'}}/>
                    </Link>
                </Box>
            ),

        },
        {field: 'title', headerName: 'Title', width: 250, editable: true},
        {field: 'subtitle', headerName: 'Subtitle', width: 250, editable: true},
        {field: 'bundle_id', headerName: 'Bundle ID', width: 250},
        {
            field: 'verified',
            headerName: 'Verified',
            width: 100,
            editable: true,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={() => {
                        const updatedRow = {...params.row, verified: !params.value};
                        handleEdit(updatedRow);
                    }}
                />
            ),
        },
        {
            field: 'created_at',
            headerName: 'Created at',
            width: 160,
            valueFormatter: (params) => format(new Date(params.value), 'HH:mm / dd.MM.yyyy'),
        },
    ];


    return (<React.Fragment>
            <Box sx={{height: 'auto', width: '100%'}}>
                <DataGrid
                    columns={columns}
                    rows={apps.map((app, index) => ({
                        id: index,
                        icon: app.icon_url,
                        uuid: app.uuid,
                        title: app.title,
                        subtitle: app.subtitle,
                        ui_name: app.ui_name,
                        bundle_id: app.bundle_id,
                        url: app.store_url,
                        verified: app.verified ? app.verified : false,
                        created_at: app.created_at,
                    }))}
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        minHeight: 400,
                    }}
                    disableRowSelectionOnClick={true}
                    disableMultipleRowSelection={true}
                    disableSelectionOnClick={true}
                    rowSelectionModel="none"
                    pageSizeOptions={[5, 10, 25]}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                        sorting: {
                            sortModel: [{field: 'created_at', sort: 'desc'}],
                        },
                    }}

                    processRowUpdate={(updatedRow) =>
                        handleEdit(updatedRow)
                    }
                    onProcessRowUpdateError={() => {
                    }}
                />
            </Box>
        </React.Fragment>
    );
}

