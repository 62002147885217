import React from 'react';
import Box from "@mui/material/Box";
import {CombinedContext} from "../../state/context";
import {UpdateSettings} from "../../services/api";
import ListItemText from "@mui/material/ListItemText";
import {Card, CardContent, ListItem} from "@mui/material";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function SettingsPage() {
    const {settings, setSettings} = React.useContext(CombinedContext);
    const {refreshSettings} = React.useContext(CombinedContext);

    const handleSave = async () => {
        try {
            await UpdateSettings(settings);
            refreshSettings();
        } catch (error) {
            console.error('Failed to update settings:', error);
        }
    }

    function formatName(name) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase().replace(/_/g, ' ');
    }

    return (
        <Box sx={{height: 'auto', width: '100%'}}>
            <List>
                <Card>
                    <CardContent style={{backgroundColor: '#191919'}}>
                        <Typography variant="h5">General</Typography>
                        <List>
                            {Object.entries(settings).map(([key, value]) => (
                                <ListItem key={key}>
                                    <ListItemText primary={formatName(key)}/>
                                    <TextField
                                        sx={{
                                            width: '100px',
                                        }} value={value} onChange={e => {
                                        try {
                                            let parsedValue = parseInt(e.target.value, 10);
                                            if (!isNaN(parsedValue)) {
                                                settings[key] = parsedValue;
                                                setSettings({...settings});
                                            }
                                        } catch (error) {
                                            console.error('Failed to parse integer:', error);
                                        }
                                    }}/>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
                <Box sx={{padding: '20px', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </List>
        </Box>
    );
}

export default SettingsPage;
