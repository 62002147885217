import * as React from 'react';
import {useContext} from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {CombinedContext} from "../../state/context";
import Filters from "./filters";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import {format} from "date-fns";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from "@mui/material/IconButton";
import {toast} from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import {Tooltip} from "@mui/material";


export default function Jobs() {
    const {jobs, devices} = useContext(CombinedContext);
    const JobStatusIcon = ({status, error}) => {
        switch (status) {
            case 'new':
                return <FiberNewIcon style={{color: 'yellow'}} titleAccess="New"/>;
            case 'success':
                return <CheckCircleIcon style={{color: '#18ce18'}} titleAccess="Success"/>;
            case 'failed':
                return (
                    <Tooltip title={error} enterDelay={0} leaveDelay={100}
                             sx={{
                                 cursor: 'pointer',
                             }} arrow={true} enterNextDelay={100}
                    >
                        <ErrorIcon style={{color: '#ff422b'}}/>
                    </Tooltip>
                );
            case 'processing':
                return <CircularProgress size={20} style={{color: 'blue'}} titleAccess="Processing"/>;
            default:
                return null;
        }
    };

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            width: 60,
            renderCell: (params) =>
                <Box display="flex" justifyContent="center" alignItems="center" padding={1}>
                    <JobStatusIcon status={params.value} error={params.row.error}/>
                </Box>,
        },
        {field: 'type', headerName: 'Type', width: 100},
        {field: 'keyword', headerName: 'Keyword', width: 150},
        {field: 'app_title', headerName: 'App Title', width: 200},
        {field: 'account_email', headerName: 'Account Email', width: 220},
        {
            field: 'device_name',
            headerName: 'Device Name',
            width: 150,
            valueGetter: (params) => {
                const device = devices.find(device => device.uuid === params.row.device_uuid);
                return device ? device.name : 'Unknown';
            },
        },
        {
            field: 'created_at',
            headerName: 'Created at',
            width: 140,
            valueFormatter: (params) => format(new Date(params.value), 'HH:mm / dd.MM.yyyy'),
        },
        {
            field: 'host_country',
            headerName: 'Host',
            width: 50,
        },
        {
            field: 'log_file_url',
            headerName: 'Log',
            width: 50,
            renderCell: (params) => {
                if (params.value) {
                    return (
                        <IconButton
                            color="primary"
                            onClick={() => {
                                window.open(params.value, '_blank');
                            }}
                            sx={{width: '40px', height: '40px'}}
                        >
                            <DownloadIcon/>
                        </IconButton>
                    );
                } else {
                    // Return null if log_file_url is null
                    return null;
                }
            },
        },
        {
            field: 'copy',
            headerName: 'Raw',
            width: 50,
            renderCell: (params) => (
                <IconButton
                    color="primary"
                    onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(params.row.raw));
                        toast.success("Copied to clipboard!");
                    }}
                    sx={{width: '40px', height: '40px'}}
                >
                    <FileCopyIcon/>
                </IconButton>
            ),
        },

    ];


    return (<React.Fragment>
            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2}}>
                <Filters/>
            </Box>
            <Box sx={{height: 'auto', width: '100%'}}>
                <DataGrid
                    columns={columns}
                    rows={jobs ? jobs.map((job, index) => ({
                        id: index,
                        uuid: job.job_uuid,
                        type: job.job_type,
                        status: job.status,
                        error: job.error,
                        keyword: job.app.keyword,
                        app_title: job.app.title,
                        app_subtitle: job.app.subtitle,
                        app_ui_name: job.app.ui_name,
                        account_email: job.acc.account_email,
                        account_city: job.acc.account_city,
                        device_uuid: job.device_uuid,
                        log_file_url: job.log_url,
                        host_country: job.host_country,
                        raw: job,
                        created_at: job.created_at,

                    })) : []}
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        minHeight: 400,
                    }}
                    disableRowSelectionOnClick={true}
                    disableMultipleRowSelection={true}
                    disableSelectionOnClick={true}
                    rowSelectionModel="none"
                    pageSizeOptions={[5, 10, 25]}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                        sorting: {
                            sortModel: [{field: 'created_at', sort: 'desc'}],
                        },
                    }}
                />
            </Box>
        </React.Fragment>
    );
}

