import React, {createContext, useCallback, useEffect, useState} from 'react';
import {GetAccounts, GetApps, GetDevices, GetJobs, GetOrders, GetSettings} from '../services/api';


// Create a context
export const CombinedContext = createContext();

// Create a provider
export const CombinedProvider = ({children}) => {
    const [accs, setAccs] = useState([]);
    const [devices, setDevices] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [apps, setApps] = useState([]);
    const [orders, setOrders] = useState([]);
    const [settings, setSettings] = useState([]);

    const refreshAccs = useCallback(async () => {
        const fetchedAccs = await GetAccounts();
        setAccs(fetchedAccs);
    }, []);

    const refreshDevices = useCallback(async () => {
        const fetchedDevices = await GetDevices();
        setDevices(fetchedDevices);
    }, []);

    const refreshJobs = useCallback(async (since, until, status) => {
        const fetchedJobs = await GetJobs(since, until, status);
        setJobs(fetchedJobs);
    }, []);

    const refreshApps = useCallback(async () => {
        const fetchedApps = await GetApps();
        setApps(fetchedApps);
    }, []);

    const refreshOrders = useCallback(async () => {
        const fetchedOrders = await GetOrders();
        setOrders(fetchedOrders);
    }, []);

    const refreshSettings = useCallback(async () => {
        const fetchedSettings = await GetSettings();
        setSettings(fetchedSettings);
    }, []);


    // Refresh data every minute
    useEffect(() => {
        const intervalId = setInterval(() => {
            refreshAccs();
            refreshDevices();
            refreshJobs();
        }, 60000); // 60000 milliseconds = 1 minute

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [refreshAccs, refreshDevices, refreshJobs]);

    return (
        <CombinedContext.Provider
            value={{
                accs,
                devices,
                jobs,
                apps,
                orders,
                settings,
                refreshAccs,
                refreshDevices,
                refreshJobs,
                refreshApps,
                refreshOrders,
                refreshSettings,
                setOrders,
                setSettings,
            }}>
            {children}
        </CombinedContext.Provider>
    );
};
