import * as React from 'react';
import {useContext} from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {format} from 'date-fns';
import {CombinedContext} from "../../state/context";
import CreateAccDialog from "./create";
import {Chip, Tooltip} from '@mui/material';
import {PatchAccount} from "../../services/api";
import Button from "@mui/material/Button";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import EventIcon from '@mui/icons-material/Event';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from "@mui/material/CircularProgress";
import {toast} from "react-toastify";
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import {RestartAltOutlined} from "@mui/icons-material";
import LoginAccDialog from "./login";
import LockIcon from '@mui/icons-material/Lock';
import UnlockIcon from '@mui/icons-material/LockOpen';


const statusList = [
    {
        status: 'new',
        color: '#00a6ff', // blue
        icon: <FiberNewIcon/>,
    },
    {
        status: 'logged in',
        color: '#18ce18', // green
        icon: <CheckCircleIcon/>,
    },
    {
        status: 'processing',
        icon: <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={20} style={{color: '#FFFF00'}} titleAccess="Processing"/>
        </Box>,
    },
    {
        status: 'scheduled',
        color: '#FFA500', // orange
        icon: <EventIcon/>,
    },
    {
        status: 'failed',
        color: '#ff422b', // red
        icon: <BlockIcon/>,
    },
    {
        status: 'locked',
        color: '#ff422b', // red
        icon: <LockIcon/>,
    },
    {
        status: 'blocked',
        color: '#444444', // grey
        icon: <CancelIcon/>,
    }
];

export default function Accs() {
    const {accs, refreshAccs} = useContext(CombinedContext);
    const {jobs} = useContext(CombinedContext);
    const {devices} = useContext(CombinedContext);
    const [createAccDiag, setCreateAccDiag] = React.useState(false);
    const [loginAccDiag, setLoginAccDiag] = React.useState(false);
    const [selectedAcc, setSelectedAcc] = React.useState(null);

    function getLastLoginJobError(login) {
        const accJobs = jobs.filter(job => job.acc.account_email === login);
        accJobs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        return accJobs.length > 0 ? accJobs[0].error : null;
    }


    const handleDeviceChange = (acc_uuid, device_uuid) => {
        PatchAccount(acc_uuid, {device_uuid}).then(() => {
            refreshAccs();
        });
    }

    const handleStatusChange = (acc_uuid, status) => {
        PatchAccount(acc_uuid, {status}).then(() => {
            refreshAccs();
        });
    }

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        const {id, login, password, state, tag} = updatedRow;
        PatchAccount(id, {email: login, password, state, tag}).then(r => {
            toast.success('Account updated');
        });

        return updatedRow;
    };

    function getStatusColor(status) {
        const statusItem = statusList.find(item => item.status === status);
        return statusItem ? statusItem.color : '#000000';
    }

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            width: 60,
            renderCell: (params) => {
                const error = getLastLoginJobError(params.row.login);
                const statusItem = statusList.find(item => item.status === params.value);
                return (
                    <Tooltip title={error || ''}>
                        <Box display="flex" justifyContent="center" alignItems="center" padding={1}
                             sx={{color: getStatusColor(params.value)}}>
                            {statusItem.icon}
                        </Box>
                    </Tooltip>
                );
            },
        },
        {field: 'login', headerName: 'Login', width: 250, editable: true},
        {
            field: 'tag',
            headerName: 'Tag',
            width: 200,
            editable: true,
            renderCell: (params) => (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Chip label={params.value} variant="outlined"
                          style={{backgroundColor: intToRGB(hashCode(params.value))}}/>
                </Box>
            ),
        },
        {field: 'password', headerName: 'Password', width: 180, editable: true},
        {
            field: 'device',
            headerName: 'Device',
            width: 180,
        },
        // {field: 'city', headerName: 'City'},

        {
            field: 'created_at',
            headerName: 'Created at',
            width: 140,
            valueFormatter: (params) => format(new Date(params.value), 'HH:mm / dd.MM.yyyy'),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 90,
            renderCell: (params) => (
                <Box display="flex" justifyContent="center" alignItems="center">
                    {(params.row.status === 'failed' || params.row.status === 'logged in') ?
                        <Box>
                            <Tooltip title="Make new" sx={{marginRight: 1}}>
                                <RestartAltOutlined style={{cursor: 'pointer', color: '#00a6ff', width: 25, height: 25}}
                                                    onClick={() => handleDeviceChange(params.row.uuid, '')}/>
                            </Tooltip>
                            <Tooltip title="Mark as banned">
                                <CancelIcon style={{cursor: 'pointer', color: '#444444', width: 25, height: 25}}
                                            onClick={() => handleStatusChange(params.row.uuid, 'blocked')}/>
                            </Tooltip>
                        </Box>
                        : null
                    }
                    {params.row.status === 'locked' ?
                        <Box>
                            <Tooltip title="Unlock" sx={{marginRight: 1}}>
                                <UnlockIcon style={{cursor: 'pointer', color: '#18ce18', width: 25, height: 25}}
                                            onClick={() => handleStatusChange(params.row.uuid, 'logged in')}/>
                            </Tooltip>
                            <Tooltip title="Mark as banned">
                                <CancelIcon style={{cursor: 'pointer', color: '#444444', width: 25, height: 25}}
                                            onClick={() => handleStatusChange(params.row.uuid, 'blocked')}/>
                            </Tooltip>
                        </Box>
                        : null

                    }
                    {params.row.status === 'new' ?
                        <Tooltip title="Login account">
                            <LoginOutlinedIcon style={{cursor: 'pointer', color: '#18ce18', width: 22, height: 22}}
                                               onClick={() => {
                                                   setSelectedAcc(params.row.uuid);
                                                   setLoginAccDiag(true);
                                               }
                                               }
                            />
                        </Tooltip>
                        : null
                    }
                </Box>
            ),
        },
    ];

    const handleAccDialogClose = () => {
        refreshAccs();
    };

    return (<React.Fragment>

            <CreateAccDialog createAccDiag={createAccDiag} setOpenAddDialog={setCreateAccDiag}
                             onAdd={handleAccDialogClose}/>

            <LoginAccDialog loginAccDiag={loginAccDiag} setLoginAccDiag={setLoginAccDiag} accUUID={selectedAcc}/>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mb: 2,
                padding: 1
            }}>
                <Button variant="contained" color="primary" startIcon={<AddIcon/>}
                        onClick={() => setCreateAccDiag(true)}>
                    Add Account
                </Button>
            </Box>
            <Box sx={{height: 'auto', width: '100%'}}>
                <DataGrid
                    autoSizeColumns={true}
                    columns={columns}
                    rows={accs.map((acc) => {
                        const device = devices.find(device => device.uuid === acc.device_uuid);
                        const device_name = device ? device.name : 'N/A';
                        return {
                            id: acc.uuid,
                            uuid: acc.uuid,
                            login: acc.login,
                            tag: acc.tag,
                            password: acc.password,
                            device: device_name,
                            city: acc.city,
                            status: acc.status,
                            created_at: acc.created_at,
                        }
                    })}
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        minHeight: 400,
                    }}
                    disableRowSelectionOnClick={true}
                    disableMultipleRowSelection={true}
                    disableSelectionOnClick={true}
                    rowSelectionModel="none"
                    pageSizeOptions={[5, 10, 25]}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 25}},
                        sorting: {
                            sortModel: [{field: 'created_at', sort: 'desc'}],
                        },
                    }}
                    processRowUpdate={processRowUpdate}
                />
            </Box>
        </React.Fragment>
    );
}

function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash += Math.imul(31, hash) + str.charCodeAt(i) | 0;
    }
    return hash;
}

function intToRGB(i) {
    const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    const hex = "00000".substring(0, 6 - c.length) + c;
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, 0.5)`;
}
